import React from 'react';
import { UserModel } from "../model/UserModel";
import { useAuth } from "./AuthContext";

export const UserContext: any = React.createContext({user: UserModel});


function UserProvider(props: any) {
  const auth = useAuth();
  return <UserContext.Provider value={auth.data.user} {...props} />;
}

let useUser = (): UserModel => {
  const context = React.useContext<UserModel>(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  if (context) {
    return Object.assign(new UserModel(), context);
  } else {
    return context;
  }
};

export { UserProvider, useUser };
