import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "./baseQuery";

interface IChangePassword {
  oldPassword: string,
  newPassword: string,
}

export const authApi = createApi({
  reducerPath: 'Auth',
  baseQuery:   baseQuery({
    baseUrl: '/auth/',
  }),
  tagTypes:    ['Auth'],
  endpoints:   (build) => ({
    changePassword: build.mutation<IChangePassword, Partial<IChangePassword>>({
      query: (form) => ({
        url:    'change-password',
        method: 'POST',
        body:   form,
      }),
    }),
  }),
});

export const {useChangePasswordMutation} = authApi;
