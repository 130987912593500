import { createApi } from '@reduxjs/toolkit/query/react';
import { AnaliticRequest, IAnalyticApiResponse, ITimestampedAnalitycRequest } from "../model/AnalyticsModel";
import { baseQuery } from "./baseQuery";

export const analyticsApi = createApi({
  reducerPath: 'analytics',
  baseQuery:   baseQuery({
    baseUrl: '/analytic',
  }),
  tagTypes:    ['Analytics', "AnalyticsTimestamped"],
  endpoints:   (build) => ({
    getAnalytics:   build.query<IAnalyticApiResponse, AnaliticRequest>({
      query:        (req: AnaliticRequest) => ({
        url:    `get`,
        method: 'POST',
        body:   req,
      }),
      providesTags: [{type: 'Analytics'}],
    }),
    getTimestamped: build.query<IAnalyticApiResponse, ITimestampedAnalitycRequest>({
      query:        (req: ITimestampedAnalitycRequest) => ({
        url:    `getTimestamped`,
        method: 'POST',
        body:   req,
      }),
      providesTags: [{type: 'AnalyticsTimestamped'}],

    }),
  }),
});


export const {useGetAnalyticsQuery, useLazyGetTimestampedQuery} = analyticsApi;
