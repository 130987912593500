import React, { useEffect } from 'react';
import { useAsync } from "react-async";
import * as authClient from '../utils/auth-client';
import { bootstrapAppData, brodcastUserChange, getToken } from '../utils/auth-client';
import { LoaderFullPage } from "../components/common";
import { notification } from "antd";
import { getSystemAuthServicePointer, setSystemAuthServicePointer } from "../utils/system";
import { UserModel } from "../model/UserModel";

export interface IAuthContext {
  data: { user: UserModel },
  login: any,
  logout: any,
  register: any,
  reload: any,
}

let authRefreshTimeout = 60 * 1000; //60s

export var AuthContext: React.Context<IAuthContext> = React.createContext({
  data:     {user: new UserModel()},
  login:    null,
  logout:   null,
  register: null,
  reload:   null,
});

export const AuthProvider = (props: any) => {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const {
          data = {user: {}},
          error,
          isRejected,
          isPending,
          isSettled,
          reload,
        }                                               = useAsync({
    promiseFn: bootstrapAppData,
  });

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);


  const logout = () => {
    if (getSystemAuthServicePointer()) {
      clearInterval(getSystemAuthServicePointer());
      setSystemAuthServicePointer(false);
    }
    brodcastUserChange();
    authClient.logout(true);
    // eslint-disable-next-line no-restricted-globals
    window.location.href = '/';
    return reload();
  };

  let timeout: NodeJS.Timeout;
  useEffect((): any => {
    if (!getSystemAuthServicePointer()) {
      setSystemAuthServicePointer(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeout = setTimeout(() => {
        setSystemAuthServicePointer(setInterval(() => {
          getToken().catch(() => {
            if (logout) {
              logout();
            }
          });
        }, authRefreshTimeout));
      }, authRefreshTimeout);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  if (error) {
    notification.error({
      message:     'Request error.',
      description: "<>error<>",
    });
    return <LoaderFullPage/>;
  }

  if (!firstAttemptFinished) {
    if (isPending) {
      return <LoaderFullPage/>;
    }
    if (isRejected) {
      notification.error({
        message:     'Request error.',
        description: error,
      });
      return <LoaderFullPage/>;
    }
  }


  const login    = (form: any) => authClient.login(form).then(() => {
    reload();
  });
  const register = (form: any) => authClient.register(form).then(reload);


  return (
    <AuthContext.Provider value={{data, login, logout, register, reload}} {...props} />
  );
};

export let useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};
