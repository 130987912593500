import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "./baseQuery";
import { IEmbed } from "../model/Embeds";

export const embedsApi = createApi({
  reducerPath: 'embed',
  baseQuery:   baseQuery({
    baseUrl: '/embed/',
  }),
  tagTypes:    ['Embeds'],
  endpoints:   (build) => ({
    getEmbedById: build.query<IEmbed, string>({
      query:        (_id) => (
        {
          url:    `get`,
          method: 'POST',
          body:   {
            id: _id,
          },
        }),
      providesTags: (result, error, _id) => [{type: 'Embeds', _id}],
    }),
    getEmbeds:    build.query<IEmbed[], void>({
      query:        () => (
        {
          url:    `list`,
          method: 'GET',
        }),
      providesTags: [{type: 'Embeds'}],
    }),
    updateEmbed:  build.mutation<IEmbed, Partial<IEmbed>>({
      query:           (data) => ({
        url:    `edit`,
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: (result, error, {_id}) => [{type: 'Embeds', _id}],
    }),
    addEmbed:     build.mutation<IEmbed, IEmbed>({
      query:           ({_id, ...data}) => ({
        url:    'add',
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: [{type: 'Embeds'}],
    }),
    deleteEmbed:  build.mutation<{ success: boolean; _id: string }, string>({
      query:           (_id) => (
        {
          url:    `delete`,
          method: 'POST',
          body:   {
            id: _id,
          },
        }),
      invalidatesTags: (result, error, _id) => [{type: 'Embeds', _id}],
    }),
  }),
});

export const {useGetEmbedsQuery, useDeleteEmbedMutation, useGetEmbedByIdQuery, useAddEmbedMutation, useUpdateEmbedMutation} = embedsApi;
