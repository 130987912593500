export enum USER_ROLES_VALUES {
  user       = 'user',
  admin      = 'ADMIN',
  superAdmin = 'SUPERADMIN'
}

export interface IUser {
  username: string;
  name: string;
  _id: string;
  role: string;
  phone: string;
  website: string;
  settings: string[];
}

export class UserModel {
  username: string;
  _id: string;
  role: USER_ROLES_VALUES          = USER_ROLES_VALUES.user;
  settings: USER_SETTINGS_VALUES[] = [];

  constructor(data?: any) {
    data && Object.assign(this, data);
  }

  public isAdmin() {
    return this.role === USER_ROLES_VALUES.admin;
  }

  public isSuperAdmin() {
    return this.role === USER_ROLES_VALUES.superAdmin;
  }

  public hasSetting(setting: USER_SETTINGS_VALUES) {
    return this.settings.indexOf(setting) !== -1;
  }
}

export const USERS_PAGE_MODE = {
  ADD:  "1",
  EDIT: "2",
};

export const USER_ROLES = {
  [USER_ROLES_VALUES.superAdmin]: "Super admin",
  [USER_ROLES_VALUES.admin]:      "Admin",
  [USER_ROLES_VALUES.user]:       "Customer",
};

export enum USER_STATUS_VALUES {
  ENABLED  = "1",
  DISABLED = "2",
}

export const USER_STATUS = {
  [USER_STATUS_VALUES.DISABLED]: "Disabled",
  [USER_STATUS_VALUES.ENABLED]:  'Enabled',
};

export enum USER_SETTINGS_VALUES {
  YB_SCRIPTS                   = "yb_scripts",
  PRO_ANALYTIC                 = "pro_analytic",
  PLAYER_CONTEXT_MENU_DISABLED = "player_context_menu_disabled",
  PLAYER_ENABLE_WHITELABEL     = "player_enable_whitelabel",
}

export const USER_SETTINGS = {
  [USER_SETTINGS_VALUES.YB_SCRIPTS]:                   "Yieldbird scripts (e.g. Price genius)",
  [USER_SETTINGS_VALUES.PRO_ANALYTIC]:                 "Pro analytics (e.g. show ad errors)",
  [USER_SETTINGS_VALUES.PLAYER_CONTEXT_MENU_DISABLED]: "Disable Veedmo branding context menu on player",
  [USER_SETTINGS_VALUES.PLAYER_ENABLE_WHITELABEL]:     "Enable whitelabel for player",
};


export class User implements IUser {
  public _id: string;
  public role: string;
  public username: string;
  public name: string;
  public phone: string;
  public website: string;
  public settings: string[];

  constructor(user?: any) {
    user && Object.assign(this, user);
  }
}
