import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "./baseQuery";
import { IPlayer } from "../model/BasePlayer";

export const playersApi = createApi({
  reducerPath: 'player',
  baseQuery:   baseQuery({
    baseUrl: '/player/',
  }),
  tagTypes:    ['Players'],
  endpoints:   (build) => ({
    getPlayerById: build.query<IPlayer, string>({
      query:        (_id) => (
        {
          url:    `get`,
          method: 'POST',
          body:   {
            id: _id,
          },
        }),
      providesTags: (result, error, _id) => [{type: 'Players', _id}],
    }),
    getPlayers:    build.query<IPlayer[], void>({
      query:        () => (
        {
          url:    `list`,
          method: 'GET',
        }),
      providesTags: [{type: 'Players'}],
    }),
    updatePlayer:  build.mutation<IPlayer, Partial<IPlayer>>({
      query:           (data) => ({
        url:    `edit`,
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: (result, error, {_id}) => [{type: 'Players', _id}],
    }),
    addPlayer:     build.mutation<IPlayer, IPlayer>({
      query:           ({_id, ...data}) => ({
        url:    'add',
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: [{type: 'Players'}],
    }),
    deletePlayer:  build.mutation<{ success: boolean; _id: string }, string>({
      query:           (_id) => (
        {
          url:    `delete`,
          method: 'POST',
          body:   {
            id: _id,
          },
        }),
      invalidatesTags: (result, error, _id) => [{type: 'Players', _id}],
    }),
  }),
});

export const {useGetPlayersQuery, useDeletePlayerMutation, useGetPlayerByIdQuery, useAddPlayerMutation, useUpdatePlayerMutation} = playersApi;
