import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "./baseQuery";
import { IAd } from "../model/Ads";

export const adsApi = createApi({
  reducerPath: 'ad',
  baseQuery:   baseQuery({
    baseUrl: '/ad/',
  }),
  tagTypes:    ['Ads'],
  endpoints:   (build) => ({
    getAdsById: build.query<IAd, string>({
      query:        (_id) => (
        {
          url:    `get`,
          method: 'POST',
          body:   {
            id: _id,
          },
        }),
      providesTags: (result, error, _id) => [{type: 'Ads', _id}],
    }),
    getAds:     build.query<IAd[], void>({
      query:        () => (
        {
          url:    `list`,
          method: 'GET',
        }),
      providesTags: [{type: 'Ads'}],
    }),
    updateAd:   build.mutation<IAd, Partial<IAd>>({
      query:           (data) => ({
        url:    `edit`,
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: (result, error, {_id}) => [{type: 'Ads', _id}],
    }),
    addAd:      build.mutation<IAd, IAd>({
      query:           ({_id, ...data}) => ({
        url:    'add',
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: [{type: 'Ads'}],
    }),
    deleteAd:   build.mutation<{ success: boolean; _id: string }, string>({
      query:           (_id) => (
        {
          url:    `delete`,
          method: 'POST',
          body:   {
            id: _id,
          },
        }),
      invalidatesTags: (result, error, _id) => [{type: 'Ads', _id}],
    }),
  }),
});

export const {useGetAdsQuery, useUpdateAdMutation, useDeleteAdMutation, useAddAdMutation, useGetAdsByIdQuery} = adsApi;
