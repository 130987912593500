import { AuthProvider } from "./AuthContext";
import { UserProvider } from "./UserContext";
import React from "react";

let AppProviders = ({children}: any) => {
  return (
    <AuthProvider>
      <UserProvider> {children} </UserProvider>
    </AuthProvider>
  );
};

export default AppProviders;
