import { NOTIFICATION_TYPE, ReduxActionTypes } from "./store";
import { message } from "antd";


export function useSystemMessage() {
  return (messageParam: string, type: NOTIFICATION_TYPE = NOTIFICATION_TYPE.info) => {
    if (messageParam) {
      let f;
      switch (type) {
        case 'success':
          f = message.success;
          break;
        case 'error':
          f = message.error;
          break;
        case 'warning':
          f = message.warning;
          break;
        case 'info':
        default:
          f = message.info;
          break;
      }
      f(`${messageParam}`);
    }
  };
}

export interface IReduxBaseAction {
  type: ReduxActionTypes;
}
