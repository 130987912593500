import { clientJson } from './ApiClient';
import { UserModel } from '../model/UserModel';

export interface AuthResponse {
  token: string;
  id: string;
}

const getTabId = () => {
  if(!sessionStorage.tabID){
    sessionStorage.tabID= Math.random();
  }
  return sessionStorage.tabID;
};


let bc = new BroadcastChannel('user_id');

bc.onmessage = (ev) => {
  if (ev.data !== getTabId()) {
  setTimeout(() => {
      window.location.href = '/';
      window.location.reload();
  }, 100);
    }
};

export interface IAuthenticatedUserProps {
  user: UserModel,
}

export const brodcastUserChange = () => {
  // @ts-ignore
  console.log(getTabId());
  // @ts-ignore
  bc.postMessage(getTabId());
};
export const bootstrapAppData = async () => {
  let user = null;
  try {
    user = await getUser();
  } catch (e) {
    // console.log(e);
  }
  if (!user) {
    return { user: null };
  }
  return {
    user: new UserModel(user),
  };
};
export const apiLocalStorageKey = 'token';
export const getCookieToken = (): string | null => {
  return window.localStorage.getItem(apiLocalStorageKey);
};

export const handleUserResponse = (apiResponse: AuthResponse) => {
  window.localStorage.setItem(apiLocalStorageKey, apiResponse.token);
};

export const getUser = () => {
  let token = getCookieToken();
  if (token == null) {
    logout();
    return Promise.reject('Not logged cookie');
  } else {
    return clientJson('user/me')
      .catch(error => {
        logout();
        return Promise.reject(error);
      });
  }
};

export const login = ({
                        username,
                        password,
                      }: { username: string, password: string }) => {
  return clientJson('auth/login', {
    username,
    password,
  })
    .then((apiResponse: AuthResponse) => {
      handleUserResponse(apiResponse);
      brodcastUserChange();
    });
};

export const register = ({
                           username,
                           password,
                           name,
                           phone,
                           website,
                         }: { username: string, password: string, name: string, phone: string, website: string }) => {
  return clientJson('auth/register', {
    username,
    password,
    name,
    phone,
    website,
  })
    .then(handleUserResponse);
};

export const logout = (force = false) => {
  // jak bedziemy recznie robic, to ok, ale jak podczas refresha sie odpali to moze spowoduje ze sie nie odpali :)
  if (force) {
    window.localStorage.removeItem(apiLocalStorageKey);
  } else {
    setTimeout(() => {
      window.localStorage.removeItem(apiLocalStorageKey);
    }, 1000);
  }
};

export const getToken = () => {
  if (getCookieToken()) {
    return clientJson('auth/getToken')
      .catch(error => {
        logout();
        return Promise.reject(error);
      })
      .then(handleUserResponse);
  } else {
    return Promise.reject('No Token');
  }
};

