import { FieldData } from "rc-field-form/es/interface";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { useSystemMessage } from "../store/storeActions";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_TYPE } from "../store/store";

let authServicePointer: any = null;

export const getSystemAuthServicePointer = () => {
  return authServicePointer;
};

export const setSystemAuthServicePointer = (pointer: any) => {
  authServicePointer = pointer;
};

interface IDataForm {
  error?: FetchBaseQueryError | SerializedError | undefined;
}

export const useApiErrors = (data: IDataForm, form: any) => {
  const systemMessage = useSystemMessage();
  const [t]           = useTranslation('generic_form');
  if (data.error !== undefined) {
    const err    = (data.error as FetchBaseQueryError);
    const status = (data.error as any).originalStatus ? (data.error as any).originalStatus : (data.error as FetchBaseQueryError).status;
    if (status === 401) {
      systemMessage(t('auth_error'), NOTIFICATION_TYPE.error);
      return;
    }
    if (status === 412) {
      let arr: FieldData[] = [];

      if (Array.isArray(err.data)) {
        for (let e of err.data) {
          arr.push({
            name:   e['name'],
            errors: [e['errors']],
          });
        }
        form.setFields(arr);
        systemMessage(t('correct_form'), NOTIFICATION_TYPE.error);
      }
    }
  }
};

export const parseBackendErrors = (errors: [], form: any) => {
  let arr: FieldData[] = [];
  for (let e of errors) {
    arr.push({
      name:   e['name'],
      errors: [e['errors']],
    });
  }
  form.setFields(arr);
};

export const isURL = (str: string) => {
  let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
};

/**
 * deflate object from
 * a {
 *   "options.ui.test":12
 * }
 *  =>
 *  a {
 *  options:{
 *    ui:{
 *      test:12
 *      }
 *  }
 *  }
 *
 * @param ob
 */
export const deflattenObject = (ob: any): any => {
  let toReturn: any = {};
  for (let i in ob) {
    if (ob.hasOwnProperty(i)) {
      let path = i.split(".");
      let tmp  = toReturn;
      let y;
      for (y = 0; y < path.length - 1; y++) {
        let k = path[y];
        if (tmp[k] === undefined) {
          tmp[k] = {};
        }
        tmp = tmp[k];
      }
      tmp[path[y]] = ob[i];
    }
  }
  return toReturn;
};


export const flattenObject = (ob: any): any => {
  let toReturn: any = {};
  for (let i in ob) {
    if (ob.hasOwnProperty(i)) {
      if ((typeof ob[i]) == 'object' && ob[i] !== null && !Array.isArray(ob[i])) {
        let flatObject = flattenObject(ob[i]);
        for (let x in flatObject) {
          if (flatObject.hasOwnProperty(x)) {
            toReturn[i + '.' + x] = flatObject[x];
          }
        }
      } else {
        if (parseInt(ob[i]) === ob[i]) {
          toReturn[i] = ob[i].toString();
        } else {
          toReturn[i] = ob[i];
        }
      }
    }
  }
  return toReturn;
};

let timer: NodeJS.Timeout[] = [];
export const debounce       = (func: () => void, id: number, timeout = 1000) => {
  clearTimeout(timer[id]);
  timer[id] = setTimeout(() => {
    func();
  }, timeout);
};
