import '../assets/styles/Common.less';
import React from "react"
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

export function LoaderFullPage() {
  const antIcon = <LoadingOutlined/>;
  return (
    <Spin indicator={antIcon} className="loader full-page"/>
  )
}
