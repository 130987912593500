import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "./baseQuery";
import { IPlaylist } from "../model/Playlists";

export const playlistsApi = createApi({
  reducerPath: 'playlist',
  baseQuery:   baseQuery({
    baseUrl: '/playlist/',
  }),
  tagTypes:    ['Playlists'],
  endpoints:   (build) => ({
    getPlaylistById: build.query<IPlaylist, string>({
      query:        (_id) => (
        {
          url:    `get`,
          method: 'POST',
          body:   {
            id: _id,
          },
        }),
      providesTags: (result, error, _id) => [{type: 'Playlists', _id}],
    }),
    getPlaylists:    build.query<IPlaylist[], void>({
      query:        () => (
        {
          url:    `list`,
          method: 'GET',
        }),
      providesTags: [{type: 'Playlists'}],
    }),
    updatePlaylist:  build.mutation<IPlaylist, Partial<IPlaylist>>({
      query:           (data) => ({
        url:    `edit`,
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: (result, error, {_id}) => [{type: 'Playlists', _id}],
    }),
    addPlaylist:     build.mutation<IPlaylist, IPlaylist>({
      query:           ({_id, ...data}) => ({
        url:    'add',
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: [{type: 'Playlists'}],
    }),
    deletePlaylist:  build.mutation<{ success: boolean; _id: string }, string>({
      query:           (_id) => (
        {
          url:    `delete`,
          method: 'POST',
          body:   {
            id: _id,
          },
        }),
      invalidatesTags: (result, error, _id) => [{type: 'Playlists', _id}],
    }),
  }),
});

export const {useGetPlaylistsQuery, useDeletePlaylistMutation, useAddPlaylistMutation, useUpdatePlaylistMutation, useGetPlaylistByIdQuery} = playlistsApi;
