import { createApi } from '@reduxjs/toolkit/query/react';
import { Video } from '../model/Video';
import { baseQuery } from './baseQuery';

export interface IVideo {
  _id: string;
  user_id: string;
  title: string;
  description: string;
  poster: string;
  create_date: string;
  publish_date: string;
  status: string;
  categories: string[];
  urls: { src: string; label: string }[];
  cdn_url: string;
  cdn_file: string;
}

export const videosApi = createApi({
  reducerPath: 'video',
  baseQuery:   baseQuery({
    baseUrl: '/video/',
  }),
  tagTypes:    ['Videos'],
  endpoints:   (build) => ({
    getVideos:       build.query<IVideo[], void>({
      query:        () => ({
        url:    'list',
        method: 'GET',
      }),
      providesTags: [{
        type: 'Videos',
        id:   'LIST',
      }],
    }),
    getVideo:        build.query<IVideo, string>({
      query:             (videoId) => ({
        url:    'get',
        method: 'POST',
        body:   {
          id: videoId,
        },
      }),
      transformResponse: (video: IVideo) => new Video(video),
      providesTags:      (_result, _error, videoId) => [
        {
          type: 'Videos',
          id:   videoId,
        },
      ],
    }),
    addPoster:       build.mutation<string, Partial<IVideo>>({
      query: (video) => ({
        url:          'add_poster',
        method:       'POST',
        body:         video,
        responseType: 'text',
      }),
    }),
    addVideo:        build.mutation<string, Partial<IVideo>>({
      query:           (data) => ({
        url:    'add',
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: () => [{
        type: 'Videos',
        id:   'LIST',
      }],
    }),
    editVideo:       build.mutation<IVideo, Partial<IVideo>>({
      query:           (data) => ({
        url:    'edit',
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: (_result, _error, video) => [
        {
          type: 'Videos',
          id:   'LIST',
        },
        {
          type: 'Videos',
          id:   video._id,
        },
      ],
    }),
    deleteVideo:     build.mutation<unknown, string>({
      query:           (videoId) => ({
        url:             'delete',
        method:          'POST',
        body:            {
          id: videoId,
        },
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: (_result, _error) => [
        {
          type: 'Videos',
          id:   'LIST',
        },
      ],
    }),
    deleteVideoFile: build.mutation<unknown, string>({
      query:           (videoId) => ({
        url:             'delete/file',
        method:          'POST',
        body:            {
          id: videoId,
        },
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: (_result, _error, videoId) => [
        {
          type: 'Videos',
          id:   videoId,
        },
      ],
    }),
  }),
});

export const {
               useGetVideosQuery,
               useGetVideoQuery,
               useAddVideoMutation,
               useDeleteVideoMutation,
               useAddPosterMutation,
               useDeleteVideoFileMutation,
               useEditVideoMutation,
             } = videosApi;
