import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { videosApi } from '../api/VideosApi';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { analyticsApi } from '../api/AnalyticsApi';
import { adsApi } from '../api/AdsApi';
import { playlistsApi } from '../api/PlaylistsApi';
import { playersApi } from '../api/PlayersApi';
import { embedsApi } from '../api/EmbedsApi';
import { usersApi } from '../api/UsersApi';
import { authApi } from '../api/AuthApi';
import { veedmoSettingsApi } from '../api/VeedmoSettingsApi';

export enum ReduxActionTypes {
  LOGOUT = 'LOGOUT',
}

export enum NOTIFICATION_TYPE {
  success = 'success',
  warning = 'warning',
  error   = 'error',
  info    = 'info'
}

const rootReducer = combineReducers({
  [adsApi.reducerPath]:            adsApi.reducer,
  [analyticsApi.reducerPath]:      analyticsApi.reducer,
  [authApi.reducerPath]:           authApi.reducer,
  [embedsApi.reducerPath]:         embedsApi.reducer,
  [playersApi.reducerPath]:        playersApi.reducer,
  [playlistsApi.reducerPath]:      playlistsApi.reducer,
  [usersApi.reducerPath]:          usersApi.reducer,
  [videosApi.reducerPath]:         videosApi.reducer,
  [videosApi.reducerPath]:         videosApi.reducer,
  [veedmoSettingsApi.reducerPath]: veedmoSettingsApi.reducer,
});

export const store = configureStore({
  reducer:    rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
    .concat(
      thunk,
      adsApi.middleware,
      analyticsApi.middleware,
      authApi.middleware,
      embedsApi.middleware,
      playersApi.middleware,
      playlistsApi.middleware,
      usersApi.middleware,
      videosApi.middleware,
      veedmoSettingsApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppState = ReturnType<typeof rootReducer>;
export const useAppDispatch: () => AppDispatch = useDispatch;
