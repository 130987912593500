import { FetchArgs, FetchBaseQueryArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getCookieToken } from "../utils/auth-client";

export const APP_URL     = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://app.veedmo.com";
export const CDN_URL     = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://cdn.veedmo-static.com/cdn";
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const baseQuery = (
  {
    baseUrl,
  }: FetchBaseQueryArgs): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> => {
  return fetchBaseQuery({
    baseUrl:        BACKEND_URL + baseUrl,
    prepareHeaders: (headers: Headers) => {
      let token = getCookieToken();
      if (token == null) {
        headers.append('Auth', "Noauth");
      } else {
        headers.append('Auth', token);
      }
      return headers;
    },
  });
};
