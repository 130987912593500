import i18n from 'i18next';
import {Translations} from "./Translations";
import {initReactI18next} from "react-i18next";
  import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources:   Translations,
    fallbackLng: 'en',
    supportedLngs:['en','pl'],
    debug:       true,
    //
    // interpolation: {
    //   escapeValue: false, // not needed for react as it escapes by default
    // }
  });


export let I18n = i18n;
