import { getCookieToken } from "./auth-client";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { BACKEND_URL } from "../api/baseQuery";


export const axiosBaseQuery = ({baseUrl}: { baseUrl: string } = {baseUrl: ''}): BaseQueryFn<{
  url: string,
  method: AxiosRequestConfig['method'],
  data?: AxiosRequestConfig['data'],
  params?: AxiosRequestConfig['params'],
},
  unknown,
  unknown> =>
  async ({url, method, data, params}) => {
    try {
      let token   = getCookieToken();
      let headers = {
        Auth: '',
      };
      if (token == null) {
        headers.Auth = "Noauth";
      } else {
        headers.Auth = token;
      }
      const result = await axios({url: BACKEND_URL + baseUrl + url, method, data, params, headers});
      return {data: result.data};
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data:   err.response?.data || err.message,
        },
      };
    }
  };

const apiCall = (endpoint: string, processResponseFn: any, body: any, ...customConfig: any) => {
  const headers = {'content-type': 'application/json', 'Auth': ''};

  let token = getCookieToken();
  if (token == null) {
    headers.Auth = "Noauth";
  } else {
    headers.Auth = token;
  }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }
  return fetch(`${BACKEND_URL}/${endpoint}`, config)
    .then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        return processResponseFn(response).then((resp: any) => Promise.reject(resp));
      }
      return processResponseFn(response);
    });
};


export const client     = (endpoint: string, body: any = null) => {
  let fn = async (response: Response): Promise<string> => {
    return await response.text();
  };
  return apiCall(endpoint, fn, body);
};
export const clientJson = (endpoint: string, body: any = null) => {
  let fn = async (response: Response): Promise<any> => {
    try {
      return await response.json();
    } catch (e) {
      return new Promise(resolve => resolve(null));
    }
  };
  return apiCall(endpoint, fn, body);
};


