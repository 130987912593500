import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import AppProviders from "./context";
import { Provider } from "react-redux";
import './model/I18n';
import { store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import App from "./layout/App";


const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
  <Provider store={store}>
    <BrowserRouter>
        <AppProviders>
          <App/>
        </AppProviders>
    </BrowserRouter>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
