import 'antd/dist/antd.less';
import '../assets/styles/Base.less';
import React from 'react';
import { LoaderFullPage } from "../components/common";
import { useUser } from '../context/UserContext';

const NonAuthenticatedPage = React.lazy(() => import("./NonAuthenticatedPage"));
const loadAuthenticatedApp = () => import("./AuthenticatedPage");
const AuthenticatedPage    = React.lazy(loadAuthenticatedApp);

const App = () => {
  const user = useUser();
  return <React.Suspense fallback={<LoaderFullPage/>}>
    {user ? <AuthenticatedPage/> : <NonAuthenticatedPage/>}
  </React.Suspense>;
};
export default App;
