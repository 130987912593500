import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { IVeedmoGlobalSetting } from '../model/VeemoGlobalSettingsModel';

export const veedmoSettingsApi = createApi({
  reducerPath: 'VeedmoSettings',
  baseQuery:   baseQuery({
    baseUrl: '/veedmo-settings/',
  }),
  tagTypes:    ['VeedmoSettings'],
  endpoints:   (build) => ({
    getSettingById: build.query<IVeedmoGlobalSetting, string>({
      query:        (_id) => (
        {
          url:    `get`,
          method: 'POST',
          body:   {
            id: _id,
          },
        }),
      providesTags: (result, error, _id) => [{
        type: 'VeedmoSettings',
        _id,
      }],
    }),
    getSettings:    build.query<IVeedmoGlobalSetting[], void>({
      query:        () => (
        {
          url:    `list`,
          method: 'GET',
        }),
      providesTags: [{ type: 'VeedmoSettings' }],
    }),
    updateSetting:  build.mutation<IVeedmoGlobalSetting, Partial<IVeedmoGlobalSetting>>({
      query:           (data) => ({
        url:    `edit`,
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: (result, error, { _id }) => [{
        type: 'VeedmoSettings',
        _id,
      }],
    }),

    addSetting: build.mutation<IVeedmoGlobalSetting, IVeedmoGlobalSetting>({
      query:           ({
                          _id,
                          ...data
                        }) => ({
        url:    'add',
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: [{ type: 'VeedmoSettings' }],
    }),
  }),
});

export const {
               useGetSettingByIdQuery,
               useGetSettingsQuery,
               useAddSettingMutation,
               useUpdateSettingMutation,
             } = veedmoSettingsApi;
