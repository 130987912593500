import { Select } from "antd";
import { IVideo } from "../api/VideosApi";

const { Option } = Select;
export const VIDEO_STATUS = {
  NONE: "1",
  PUBLISHED: "2",
};

export const VIDEO_STATUS_NAMES = {
  [VIDEO_STATUS.NONE]: " - ",
  [VIDEO_STATUS.PUBLISHED]: "Published",
};

export const VIDEO_PAGE_MODE = {
  ADD: "1",
  EDIT: "2",
};

export class Video implements IVideo {
  public _id: string = "";
  public title: string = "";
  public description: string = "";
  public user_id: string = "";
  public poster: string = "";
  public create_date: string = Math.floor(Date.now() / 1000).toString();
  public publish_date: string = "0";
  public status: string = "";
  public urls: { src: string; label: string }[] = [];
  public categories: string[] = [];
  public cdn_url: string = "";
  public cdn_file: string = "";

  constructor(video?: IVideo) {
    video = {
      ...this,
      ...video,
    };

    video && Object.assign(this, video);
  }
}

export const renderVideosOptions = (array: IVideo[]) => {
  let components = [];

  for (let obj of array) {
    components.push(
      <Option key={obj._id} value={obj._id}>
        {obj.title}
      </Option>
    );
  }
  return components;
};
