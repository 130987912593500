import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "./baseQuery";
import { IUser } from "../model/UserModel";

export interface IChangeContextResponse{
  token: string;
}
export const usersApi = createApi({
  reducerPath: 'Users',
  baseQuery:   baseQuery({
    baseUrl: '/user/',
  }),
  tagTypes:    ['Users'],
  endpoints:   (build) => ({
    getUserById: build.query<IUser, string>({
      query:        (_id) => (
        {
          url:    `get`,
          method: 'POST',
          body:   {
            id: _id,
          },
        }),
      providesTags: (result, error, _id) => [{type: 'Users', _id}],
    }),
    getUsers:    build.query<IUser[], void>({
      query:        () => (
        {
          url:    `list`,
          method: 'GET',
        }),
      providesTags: [{type: 'Users'}],
    }),
    updateUser:  build.mutation<IUser, Partial<IUser>>({
      query:           (data) => ({
        url:    `edit`,
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: (result, error, {_id}) => [{type: 'Users', _id}],
    }),

    addUser:       build.mutation<IUser, IUser>({
      query:           ({_id, ...data}) => ({
        url:    'add',
        method: 'POST',
        body:   data,
      }),
      invalidatesTags: [{type: 'Users'}],
    }),
    changeContext: build.mutation<IChangeContextResponse, string>({
      query:           (_id) => ({
        url:    'context',
        method: 'POST',
        body: {
          id: _id,
        },
      }),
    }),
  }),
});

export const {useGetUsersQuery, useChangeContextMutation, useGetUserByIdQuery, useUpdateUserMutation} = usersApi;
